import React, { useContext, useEffect, useState } from 'react';
import { HiOutlineVideoCamera } from "react-icons/hi2";
import { BsTelephone } from "react-icons/bs";
import { LiaSearchSolid } from "react-icons/lia";
import { HiOutlineX } from "react-icons/hi";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import user from '../../Assests/logo/wyzer-logo.jpg';
import './chatBoxHeader.css';
import { HiSearch } from "react-icons/hi";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import logo from '../../Assests/logo/wyzer-logo.jpg'
import { FaTrash } from "react-icons/fa";

import {
    getFirestore,
    collection,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    doc,
    getDoc,
    getDocs,
    getCollections,
    updateDoc,
    where,
    serverTimestamp, increment,
    deleteDoc
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { app } from '../../Firebase/firebase';
import { AuthContext } from '../../SharedContext/SharedContext';
import DeleteChannelModal from '../DeleteChannelModal/DeleteChannelModal';
const database = getFirestore(app);
export const storage = getStorage(app);


const ChatBoxHeader = ({ setOpenChannelDetailsPage, openChannelDetailsPage, searchTextInMessages, totalSearchText }) => {
    const { index } = useParams();
    const [channel, setChannel] = useState({});
    const { loggedUser, deleteCollectionAndDocument } = useContext(AuthContext);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [searchText, setSearchText] = useState(false);
    const [channelIndex, setChannelIndex] = useState({});
    const [showDeleteChannelModal, setShowDeleteChannelModal] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const documentPath = `${process.env.REACT_APP_CHATINDEX}/${index}`;
        const documentRef = doc(database, documentPath);

        const unsubscribe = onSnapshot(documentRef, (documentSnapshot) => {
            if (documentSnapshot?.exists()) {
                const existingData = documentSnapshot?.data();
                setChannelIndex(existingData);
            }
        });
        return unsubscribe;
    }, [index])


    let otherParticipant = channelIndex?.members?.find(participant => participant.id !== loggedUser.id);
    // const img = channelIndex?.thread_icon ? channelIndex?.thread_icon : otherParticipant?.image;
    const img = (channelIndex?.thread_icon && channelIndex?.thread_icon?.includes('no_image.jpg')) || 
            (otherParticipant?.image && otherParticipant?.image?.includes('no_image.jpg')) 
    ? logo
    : (channelIndex?.thread_icon || otherParticipant?.image || logo);
    const name = channelIndex?.thread_name ? channelIndex?.thread_name : otherParticipant?.name;

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isClickInsideSearchBar = event.target.closest(".clickSearchBar");
            if (!isClickInsideSearchBar) {
                setShowSearchBar(false);
            }
        };

        document.body.addEventListener("click", handleClickOutside);

        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, []);
    const handleError = (event) => {
        event.target.src = logo;
    };

    const deleteChannelAndMessages = async () => {
        await deleteCollectionAndDocument(`${process.env.REACT_APP_CHATINDEX}/${index}/messages`,`${process.env.REACT_APP_CHATINDEX}/${index}`,index);
        navigate('/');
    };
    return (
        <section>
            <div className='ChatBoxHeaderContainer openDetailsPage'

            >
                <div onClick={() => setOpenChannelDetailsPage(!openChannelDetailsPage)} className='ChatBoxHeaderUser'
                >
                    <img src={img ? img : user} alt="" onError={handleError}
                    />
                    <div>
                        <p
                        >{name}</p>
                    </div>

                </div>
                <div className='ChatBoxHeaderOptions clickSearchBar'

                >
                    <FaTrash onClick={() => setShowDeleteChannelModal(!showDeleteChannelModal)} className='chatDltBtn' />
                    <HiSearch className='' onClick={() => setShowSearchBar(!showSearchBar)} style={{ color: "#38ACEC" }} />
                    <NavLink to='/' style={{ color: "#38ACEC" }}><HiOutlineX /></NavLink>
                    {
                       showSearchBar && <div className='ChatBoxHeadertextSearch' >
                            <div className='ChatBoxHeadertextSearchBox' >
                                <input onChange={(e)=>searchTextInMessages(e.target.value)} type='text' name='text-search' id='text-search' placeholder='search within chat' />
                                <span style={{ fontSize: "10px", color: "gray", position: "absolute", zIndex: "200", top: "0px", right: "0px" }}>{totalSearchText} of {totalSearchText}</span>
                            </div>
                            <MdKeyboardArrowUp className='ChatBoxHeadertextSearchIcon' />
                            <MdKeyboardArrowDown className='ChatBoxHeadertextSearchIcon' />
                            <HiOutlineX onClick={()=>setShowSearchBar(false)} className='' />

                        </div>
                    }
                </div>

            </div>
            {showDeleteChannelModal && <DeleteChannelModal deleteChannelAndMessages={deleteChannelAndMessages} setShowDeleteChannelModal={setShowDeleteChannelModal} />}
        </section>
    );
};

export default ChatBoxHeader;