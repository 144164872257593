import React, { useContext, useEffect, useState } from 'react';
import './deleteMessage.css'
import { AuthContext } from '../../SharedContext/SharedContext';

const DeleteMessage = ({ deleteMsg, closeModal, deletedMsg }) => {
    const [showDeleteForAll, setShowDeleteForAll] = useState(false)
    const { loggedUser, } = useContext(AuthContext);
    useEffect(()=>{
        if(deletedMsg?.sender?.id===loggedUser.id){
            setShowDeleteForAll(true)
        }
    },[deletedMsg?.sender?.id, deletedMsg, loggedUser.id]);

    return (
        <div className='deleteBtnContainer'>
            <h3>Delete Message</h3>
            <p>You can delete message for everyone or just for yourself</p>

            <div className='deleteBtns' >
                <button onClick={() => deleteMsg(1)} className='dltFrMe'>Delete for me</button>
               {showDeleteForAll && <button onClick={() => deleteMsg(2)} className='dltFrEveryOne' >Delete for everyone</button>}
                <button className='cancelBtn' onClick={closeModal}>Cancel</button>
            </div>
        </div>
    );
};

export default DeleteMessage;