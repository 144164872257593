
import React, { useEffect, useRef, useState } from "react";
import { FaRegSmile } from "react-icons/fa";
import { ImAttachment } from "react-icons/im";
import { MdOutlineKeyboardVoice } from "react-icons/md";
import { IoSend } from "react-icons/io5";
import './chatBoxFooter.css'
import FileType from "../FileType/FileType";
import Picker from 'emoji-picker-react';
import EmojiPicker from 'emoji-picker-react';

const ChatboxFooter = ({ sendMessage, setMessage, message, loading, uploadImage, setVal, val, setShowSelectFileType, showSelectFileType, setOpenEmoji, openEmoji,currentChatId }) => {
    const [chosenEmoji, setChosenEmoji] = useState(null);

    const textAreaRef = useRef(null);

    // Reset the message state when currentChatId changes
    useEffect(() => {
        setMessage('');
        setVal('');
    }, [currentChatId, setMessage, setVal]);

    const showEmoji = () => {
        setOpenEmoji(!openEmoji)
        setShowSelectFileType(false)
    }
    const showFileTypeSelect = () => {
        setOpenEmoji(false)
        setShowSelectFileType(!showSelectFileType)
    }

    const resizeTextArea = () => {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    };
    useEffect(resizeTextArea, [val]);
    const onChange = e => {
        setVal(e.target.value);
        setMessage(e.target.value);

    };


    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(event);
        setMessage((prevMessage) => prevMessage + event.emoji);
    };

    const submitMessage = (e) => {
        if (window.event.keyCode === 13 && window.event.shiftKey === false) {
            sendMessage({ message: e.target.value });
            setVal('')
        } else if (window.event.keyCode === 13 && window.event.shiftKey === true) {
            // setMessage(e.target.value);
        }
    }

    return (
        <section style={{ position: "relative" }}>
            <div>
                {
                    showSelectFileType && !openEmoji && <FileType uploadImage={uploadImage} />
                }
                {openEmoji && !showSelectFileType && <Picker onEmojiClick={onEmojiClick} />}
            </div>
            <div className="chatboxFooter"
            >
                <div className="chatboxFooterAttachments"
                >
                    <button style={{color:"#38ACEC", fontWeight:"bold", fontSize:"18px"}} onClick={showEmoji}><FaRegSmile /></button>
                    <button style={{color:"#38ACEC", fontWeight:"bold", fontSize:"18px"}} onClick={showFileTypeSelect}><ImAttachment /></button>
                </div>
                <textarea ref={textAreaRef} rows={1}
                    onChange={onChange}
                    onKeyUp={e => submitMessage(e)}
                    value={message}
                    className="chatboxFooterTextArea"
                    style={{ resize: "none", overflowY: "hidden", }}
                    placeholder="Type a message"
                    name="msg"
                    id="msg"
                />

                <button style={{ color: loading ? "#1f2937" : "#38ACEC" ,fontWeight:"bold", fontSize:"22px"}} disabled={loading} onClick={() => sendMessage({ message })} className="chatboxFooterSendMsgBtn" ><IoSend /></button>
            </div>
        </section>
    );
};

export default ChatboxFooter;

