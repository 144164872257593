import { useContext, useRef, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/router";
import './App.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "./SharedContext/SharedContext";
import FullPageLoader from "./Components/Loader/FullPageLoader";

function App() {
  const { loading } = useContext(AuthContext);

  return (
    <>
      {
        loading ? <FullPageLoader /> : <><RouterProvider router={router} />
          <ToastContainer /></>
      }
    </>


  );
}

export default App;
