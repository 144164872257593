import React, { useContext, useEffect, useRef, useState } from 'react';
import ChatBoxHeader from '../../Components/ChatHeader/ChatBoxHeader';
import ChatboxFooter from '../../Components/ChatFooter/ChatboxFooter';
import Chatbox from '../../Components/ChatBox/Chatbox';
import { toast } from 'react-toastify';
import moment from 'moment';
import ChannelDetailsPage from '../../Components/ChannelDetailsPage/ChannelDetailsPage/ChannelDetailsPage';
import './chatBoxContainer.css'
import { useNavigate, useParams } from 'react-router-dom'
import {
    getFirestore,
    collection,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    doc,
    getDoc,
    getDocs,
    getCollections,
    updateDoc,
    where,
    serverTimestamp, increment, Timestamp
} from "firebase/firestore";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AuthContext } from "../../SharedContext/SharedContext";
import { app } from '../../Firebase/firebase'; 

const database = getFirestore(app);
export const storage = getStorage(app);

const ChatBoxContainer = () => {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [mouseMoving, setMouseMoving] = useState(false)
    const [dateToShow, setDateToShow] = useState('')
    const [val, setVal] = useState("");
    const [showSelectFileType, setShowSelectFileType] = useState(false)
    const [openEmoji, setOpenEmoji] = useState(false);
    const [deletedMsg, setDeletedMsg] = useState({})
    const [deleteMsgModal, setDeleteMsgModal] = useState(false);
    const navigate = useNavigate();
    const { index } = useParams();
    const current_timestamp = Timestamp.fromDate(new Date())
    const [channelIndex, setChannelIndex] = useState({})
    const { loggedUser,user} = useContext(AuthContext);
    const [msgSent, setMsgSent] = useState(false)
    const chatBoxRef = useRef(null);
    const [openChannelDetailsPage, setOpenChannelDetailsPage] = useState(false);
    const [totalSearchText, setTotalSearchText] = useState(0);
    const messagesdocumentReference = collection(
        database,
        `${process.env.REACT_APP_CHATINDEX}/${index}/messages`,
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isClickInsideHeader = event.target.closest(".openDetailsPage");
            if (!isClickInsideHeader) {
                setOpenChannelDetailsPage(false);
            }
        };

        document.body.addEventListener("click", handleClickOutside);

        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, []);
    let LoggedInUser = channelIndex.members && channelIndex.members.find(participant => participant.id === loggedUser.id)
    useEffect(() => {
        // chatIndex()
        const documentPath = `${process.env.REACT_APP_CHATINDEX}/${index}`;
        const documentRef = doc(database, documentPath);

        const unsubscribe = onSnapshot(documentRef, (documentSnapshot) => {
            if (documentSnapshot?.exists()) {
                const existingData = documentSnapshot?.data();
                setChannelIndex(existingData);
            }
        });
        return unsubscribe;
    }, [index])

    useEffect(() => {
        // fetchMessages();
        const messagesdocumentReference = collection(
            database,
            `${process.env.REACT_APP_CHATINDEX}/${index}/messages`,
        );
        const unsubscribe = onSnapshot(query(messagesdocumentReference, orderBy("created_at", "asc"),
            // where("message_delete_status", "!=", 2)
        ), (querySnapshot) => {
            const _messages = [];
            querySnapshot?.docs?.forEach((snapshot) => {
                const d = snapshot.data();
                _messages.push({ ...d, message_id: snapshot.id });
            });
            setMessages(_messages);
        });

        // Remember to unsubscribe when the component unmounts or is no longer needed
        return () => unsubscribe();
    }, [index]);

    const updateOrCreateDocument = async (path, data) => {
        try {
            const documentPath = `${path}`;

            // Check if the document already exists
            const documentRef = doc(database, documentPath);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists() && Object.keys(documentSnapshot.data()).length) {
                // If the document exists, update it
                const existingData = documentSnapshot.data();

                if (existingData.members && data.sender_id) {
                    data.members = existingData.members.map(member => {
                        if (member.id !== data.sender_id) {
                            return {

                                ...member,
                                unread_count: (member.unread_count || 0) + 1,

                            };
                        }
                        return member;
                    });
                };
                await updateDoc(documentRef, data);
            } else {
                toast("no channel available ");
                navigate('/')

            }

            console.log('Document updated or created successfully!');
        } catch (error) {
            console.error('Error updating or creating document:', error);
        }
    };

    const sendMessage = async ({ message = "", medias = [] }) => {
        setMsgSent(false)
        message = message?.trim().replace(/\r|\n/g, '<br>');
        let message_id = String(+new Date())
        if (message.match(/^\s*$/) && medias.length === 0) {
            setVal('')
            return false;
        }
        setLoading(true)
        setVal('')
        setMessage('');
        setShowSelectFileType(false)
        setOpenEmoji(false)
        const messageData = {
            created_at: current_timestamp,
            medias: medias.length > 0 ? medias?.map(media => ({
                type: media.type,
                file_name: media.file_name,
                url: media.url,
            })) : [],
            text: message ? message : "",
            message_delete_status: 0,
            sender_id: loggedUser?.id,
            sender: {
                id: LoggedInUser?.id,
                name: LoggedInUser?.name,
                image: LoggedInUser?.img ? LoggedInUser?.img : null
            },
            receivers: channelIndex?.thread_member_ids.map(id => ({
                id: id,
                read_at: null,
                delivered_at: null,
            })),
            reaction: [
                // {
                //     id: "",
                //     react: ""
                // }
            ],
            seen_by: [loggedUser.id]
        };


        await setDoc(
            doc(
                database,
                `${process.env.REACT_APP_CHATINDEX}/${index}/messages`,
                `${message_id}`
            ),
            messageData
        );
        const channelData = {
            message: message,
            message_id: message_id,
            message_delete_status: 0,
            message_time: current_timestamp,
            sender_id: loggedUser?.id,
            sender: {
                id: LoggedInUser?.id,
                name: LoggedInUser?.name,
                image: LoggedInUser?.img ? LoggedInUser?.img : null
            },
            hide_for_me:[]
        };

        updateOrCreateDocument(`${process.env.REACT_APP_CHATINDEX}/${index}`, channelData);
        setLoading(false);
        setMsgSent(true);
        fetch(`${process.env.REACT_APP_BaseUrl}/user/send-notification`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,Authorization: `Bearer ${user.token}`},
            body: JSON.stringify({
                "receiver_id": channelIndex?.thread_member_ids.find(member=>member!==loggedUser.id),
                "message": message,
                "type": "message"

            })
        })
        .then(res => {
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return res.json();
        })
        .then(response=>{
            console.log("response");
        })
        .catch(error => {
            console.log("Error:", error);
        });
    };

    const uploadImage = async ({ file }) => {
        const formData = new FormData();
        formData.append('image', file);

        fetch(`${process.env.REACT_APP_BaseUrl}/chat/file-upload`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(data => {
                if (data.data.file_path) {
                    sendMessage({
                        // message: `${loggedUser.name} has sent an image`,
                        message: "Photo",
                        medias: [{
                            type: "image",
                            file_name: file.name,
                            url: data?.data?.file_path,
                        }]
                    })
                }

            })
            .catch(error => {
                console.error('Error uploading image:', error);
            });
    };

    const updateMsgEmoji = (msg, emoji) => {
        console.log("msg", msg, emoji);
    };

    const openModal = (message) => {
        setDeleteMsgModal(true)
        setDeletedMsg(message)
    }

    const closeModal = () => {
        setDeleteMsgModal(false)
        setDeletedMsg({})
    };

    const deleteMsg = async (deleteStatus) => {

        const messageData = {};
        const channelData = {};

        if(deletedMsg.sender.id!==loggedUser.id){
            messageData.hide_for_me = [loggedUser.id]
            channelData.hide_for_me = [loggedUser.id]
        }else{
            messageData.message_delete_status = deleteStatus;
            channelData.message_delete_status = deleteStatus;
        };
        closeModal();
        const documentRef = doc(database, `${process.env.REACT_APP_CHATINDEX}/${index}/messages/${deletedMsg.message_id}`);
        await updateDoc(documentRef, messageData);

        //update channel index

        if (channelIndex.message_id == deletedMsg.message_id) {

            channelData.message = deletedMsg.text;

            if (deleteStatus === 2) {
                channelData.message = "This message was deleted"
            };

            updateOrCreateDocument(`${process.env.REACT_APP_CHATINDEX}/${index}`, channelData);
        }
    };
    // const deleteMsg = async (deleteStatus) => {

    //     const messageData = {}

    //     if(deletedMsg.sender.id!==loggedUser.id){
    //         console.log("deletedMsg",deletedMsg);
    //         messageData.hide_for_me = [loggedUser.id]
    //     }else{
    //         messageData.message_delete_status = deleteStatus;
    //     }
        
    //     return
    //     closeModal()
    //     // const messageData = {
    //     //     message_delete_status: deleteStatus
    //     // };
    //     const documentRef = doc(database, `${process.env.REACT_APP_CHATINDEX}/${index}/messages/${deletedMsg.message_id}`);
    //     await updateDoc(documentRef, messageData);

    //     //update channel index

    //     if (channelIndex.message_id == deletedMsg.message_id) {

    //         let message = deletedMsg.text

    //         if (deleteStatus === 2) {
    //             message = "This message was deleted"
    //         }

    //         const channelData = {
    //             message: message,
    //             message_delete_status: deleteStatus,
    //         };
    //         updateOrCreateDocument(`${process.env.REACT_APP_CHATINDEX}/${index}`, channelData);
    //     }
    // };

    const searchTextInMessages = (text) => {
        // Convert search text to lowercase
        const searchTextLowerCase = text.toLowerCase();

        // Filter messages based on case-insensitive search condition
        let total = 0
        const filteredMessages = messages.map(message => {
            if (message?.text && searchTextLowerCase !== '') {
                // Create regular expression to match whole word or partial word
                // const regex = new RegExp('\\b' + searchTextLowerCase + '\\b|' + searchTextLowerCase, 'gi');
                const regex = new RegExp('\\b' + searchTextLowerCase + '\\b', 'gi');
                const match = message.text.match(regex);
                // let total = 0
                if (match) {
                    // If match is found, mark the text
                    const markedText = message.text.replace(regex, "<mark>$&</mark>");
                    total += 1
                    return { ...message, markedText };
                };
            } else {
                delete message.markedText
                return { ...message, };
            }
            return message;
        });

        // Update state with filtered messages
        setMessages(filteredMessages);
        setTotalSearchText(total);

        // Return the filtered messages if needed
        return filteredMessages;
    };


    return (
        <div className="chatBoxContainer"
        >
            <ChatBoxHeader totalSearchText={totalSearchText} searchTextInMessages={searchTextInMessages} setOpenChannelDetailsPage={setOpenChannelDetailsPage} openChannelDetailsPage={openChannelDetailsPage} index={index}/>
            <Chatbox index={index} openModal={openModal} closeModal={closeModal} deleteMsgModal={deleteMsgModal} setDeleteMsgModal={setDeleteMsgModal} deletedMsg={deletedMsg} setDeletedMsg={setDeletedMsg} deleteMsg={deleteMsg} chatBoxRef={chatBoxRef} channelIndex={channelIndex} LoggedInUser={LoggedInUser} updateMsgEmoji={updateMsgEmoji} setDateToShow={setDateToShow} messages={messages} />
            <ChatboxFooter setShowSelectFileType={setShowSelectFileType}
                setOpenEmoji={setOpenEmoji}
                showSelectFileType={showSelectFileType}
                openEmoji={openEmoji} setVal={setVal} val={val} uploadImage={uploadImage} loading={loading} message={message} setMessage={setMessage} sendMessage={sendMessage} 
                currentChatId={index}/>

            <ChannelDetailsPage messages={messages} loggedUser={loggedUser} channelIndex={channelIndex} openChannelDetailsPage={openChannelDetailsPage} />
        </div>
    );
};

export default ChatBoxContainer;