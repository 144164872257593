import React from 'react';

const SpinLoader = () => {
    return (
        <div style={{margin:"auto"}}>
            <span className="spinner"></span>
        </div>
    );
};

export default SpinLoader;