import React, { useContext, useEffect, useRef, useState } from "react";
import { IoCheckmarkOutline, IoCheckmarkDoneSharp } from "react-icons/io5";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useParams } from "react-router-dom";
import './chatbox.css'
import Message from "./Message";
import { AuthContext } from "../../SharedContext/SharedContext";
import DeleteMessage from "../DeleteMessageModal/DeleteMessage";
import {
    getFirestore,
    collection,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    doc,
    getDoc,
    getDocs,
    getCollections,
    updateDoc,
    where,
    serverTimestamp, increment, Timestamp
} from "firebase/firestore";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from '../../Firebase/firebase'; 

const database = getFirestore(app);
export const storage = getStorage(app);
const Chatbox = ({ index, messages, setDateToShow, updateMsgEmoji, LoggedInUser, channelIndex, chatBoxRef, deleteMsg, deletedMsg, setDeletedMsg, deleteMsgModal, setDeleteMsgModal, openModal, closeModal,  }) => {
    const { id } = useParams();
    const [openEmojiMessageId, setOpenEmojiMessageId] = useState(null);
    const { loggedUser, } = useContext(AuthContext);
    const endOfTheMsg = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageId, setCurrentImageId] = useState(null);
    const current_timestamp = Timestamp?.fromDate(new Date())

    const openLightbox = (imageId) => {
        setCurrentImageId(imageId);
        setIsOpen(true);
    };

    const toggleEmojiPicker = (messageId) => {
        // setOpenEmojiMessageId(openEmojiMessageId === messageId ? null : messageId);
        setOpenEmojiMessageId((prevId) => (prevId === messageId ? null : messageId));
    };

    useEffect(() => {
        // Scroll to the end of the message list when messages update
        if (endOfTheMsg.current) {
            endOfTheMsg.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isClickInsideEmoji = event.target.closest(".msgEmoji");
            if (!isClickInsideEmoji) {
                setOpenEmojiMessageId(null);
            }
        };

        document.body.addEventListener("click", handleClickOutside);

        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, []);
    const medias = messages?.map((message) => message.medias).flat();

    useEffect(() => {
        const handleIntersection = async (entries) => {
            entries?.forEach(async (entry) => {
                if (entry?.isIntersecting) {
                    const messageElement = entry?.target;
                    const messageId = parseInt(messageElement.getAttribute("data-message-id"), 10);

                    const messageToUpdate = messages.find(message => message?.message_id == messageId && !message?.seen_by?.includes(loggedUser.id));

                    if (messageToUpdate) {
                        let updateReceiver = messageToUpdate?.receivers && messageToUpdate?.receivers.find(receiver => receiver?.id === loggedUser?.id && (receiver?.read_at === null || receiver?.read_at === ''));

                        let seen_by = [];

                        let receiver_id = updateReceiver?.id;

                        if (!receiver_id) {
                            receiver_id = loggedUser?.id;
                        }

                        if (messageToUpdate?.seen_by?.length > 0) {
                            seen_by = messageToUpdate?.seen_by;
                        }


                        const messageData = {
                            receivers: [{ id: receiver_id, read_at: current_timestamp, delivered_at: current_timestamp }],
                            seen_by: seen_by?.includes(loggedUser.id) ? seen_by : [...seen_by, loggedUser.id]
                        };

                        // Update Channel  
                        const documentPath = `${process.env.REACT_APP_CHATINDEX}/${index}`;

                        const indexDocumentRef = doc(database, documentPath);
                        const documentSnapshot = await getDoc(indexDocumentRef);

                        if (documentSnapshot?.exists() && Object?.keys(documentSnapshot?.data()).length) {
                            const existingMembers = documentSnapshot?.data()?.members || [];

                            const updatedMembers = existingMembers.map(member => {
                                if (member?.id === loggedUser?.id) {
                                    return {
                                        ...member,
                                        unread_count: 0, // Math.max(member?.unread_count -1, 0),  Ensure unread_count is not negative
                                    };
                                }
                                return member;
                            });
                            await updateDoc(indexDocumentRef, {
                                members: updatedMembers
                            });

                        } else {
                            // Document does not exist, handle this case
                            console.error('Document does not exist:', documentPath);
                        }

                        //update messages

                        const messagedocumentPath = `${process.env.REACT_APP_CHATINDEX}/${index}/messages/${messageToUpdate?.message_id}`;
                        const messageIndexDocumentRef = doc(database, messagedocumentPath);
                        const messageDocumentSnapshot = await getDoc(messageIndexDocumentRef);

                        if (messageDocumentSnapshot?.exists() && Object?.keys(messageDocumentSnapshot?.data()).length) {

                            await updateDoc(messageIndexDocumentRef, messageData);

                        } else {
                            // Document does not exist, handle this case
                            console.error('Document does not exist:', documentPath);
                        };
                    } else {
                        return false
                    }

                }
            });
        };

        const options = {
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin
            threshold: 0 // Trigger when any part of the message enters the viewport
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        // Start observing message elements
        messages.forEach(message => {
            const messageElement = document.getElementById(`message-${message?.message_id}`);
            if (messageElement) {
                observer.observe(messageElement);
            }
        });

        return () => {
            // Clean up observer
            observer.disconnect();
        };
    }, [messages]);

    console.log('messages',messages);

    return (
        <div style={{ position: "relative" }} className="chatBox clickSearchBar" ref={chatBoxRef}
        >
            <div className="chatBoxComponent"
            >
                <div style={{ pointerEvents: deleteMsgModal && "none" }} className="chatBoxComponentContainer"
                >
                    {messages?.map((message, index) => (
                        <Message openLightbox={openLightbox} key={message.message_id} openModal={openModal} channelIndex={channelIndex} LoggedInUser={LoggedInUser}
                            isOpenEmojiPicker={openEmojiMessageId === message.message_id}
                            toggleEmojiPicker={toggleEmojiPicker} updateMsgEmoji={updateMsgEmoji} setDateToShow={setDateToShow} message={message} />
                    ))}
                    <div ref={endOfTheMsg} />
                </div>
            </div>
            {isOpen && (
                <Lightbox
                    mainSrc={medias.find((image) => image.url === currentImageId).url}
                  
                    onCloseRequest={() => setIsOpen(false)}
                />
            )}
            {deleteMsgModal && <DeleteMessage deletedMsg={deletedMsg} deleteMsg={deleteMsg} closeModal={closeModal} />}
        </div>
    );
};

export default Chatbox;
