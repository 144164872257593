import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import Linkify from 'react-linkify';
import logo from '../../Assests/logo/wyzer-logo.jpg'
import EmojiPicker from 'emoji-picker-react';
import { FaRegSmile } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import { AuthContext } from '../../SharedContext/SharedContext';
import { IoCheckmarkDoneSharp, IoCheckmarkOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
const Message = ({ message, setDateToShow, updateMsgEmoji, isOpenEmojiPicker, toggleEmojiPicker, LoggedInUser, channelIndex, openModal, openLightbox }) => {
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const { loggedUser, user } = useContext(AuthContext);
    const [event, setEvent] = useState("")

    let userDetails = channelIndex.members && channelIndex.members.find(participant => participant.id === message.sender?.id);
    const img = (userDetails?.image && !userDetails?.image?.includes('no_image.jpg'))
        ? userDetails?.image : logo;

    // console.log("message",message);

    let isRead = channelIndex.members && channelIndex.members.every(member => (message?.seen_by?.includes(member.id)));
    // console.log('isRead',isRead);
    const messageWithMarks = message?.markedText?.split('<br>').map((line, index) => {

        return (
            <p style={{ wordBreak: 'break-all' }} key={index}>
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a style={{ color: "blue" }} href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
                        {decoratedText}
                    </a>
                )}>
                    <span dangerouslySetInnerHTML={{ __html: line }} />
                    {/* <span style={{color:"red"}}>{line}</span> */}
                </Linkify>
                <br />
            </p>
        );
    });

    const messageWithBreaks = message?.text.split('<br>').map((line, index) => (
        <p style={{ wordBreak: 'break-all' }} key={index}>
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                <a style={{ color: "blue" }} href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
                    {decoratedText}
                </a>
            )}>
                {line}
            </Linkify>
            <br />
        </p>
    ));
    const messageWithImage = message?.medias.map((file, index) => (
        <div onClick={() => openLightbox(file?.url)} key={index} style={{ width: "100px", margin: "auto" }}>
            <img style={{ width: "100%", borderRadius: "5px" }} src={file.url} alt='' />
        </div>
    ));

    // const messageWithEvents = 

    useEffect(() => {
        if (message?.event_id?.length > 0) {
            fetch(`${process.env.REACT_APP_BaseUrl}/user/events/${message.event_id}`, {
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user?.token}` },
            })
                .then(res => res.json())
                .then(data => {
                    console.log("data", data?.data?.event_info);
                    setEvent(data?.data?.event_info)
                })
        }
    }, [message, user?.token])


    const messageTimeMillis = message.created_at.seconds * 1000 + message.created_at.nanoseconds / 1000000;
    const currentDate = moment();
    const messageDate = moment(messageTimeMillis);
    const minutesAgo = currentDate.diff(messageDate, 'minutes');
    let displayTime;
    if (minutesAgo < 1) {
        displayTime = 'Just now';
    } else if (minutesAgo < 59) {
        // If less than 59 minutes, display the time only
        displayTime = `${minutesAgo} min${minutesAgo !== 1 ? 's' : ''} ago`;
    } else if (currentDate.isSame(messageDate, 'day')) {
        displayTime = messageDate.format('hh:mm a');
    } else {
        // If not from today, display the date
        displayTime = messageDate.format('MMM DD, YYYY');
    };

    const showEmoji = () => {
        toggleEmojiPicker(message.message_id);
    };


    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(event.emoji);
        toggleEmojiPicker(message.message_id); // Close emoji picker
        updateMsgEmoji(message, event.emoji);
    };

    const handleError = (event) => {
        event.target.src = logo;
    };

    let messageNotToShow = message?.hide_for_me?.includes(loggedUser.id) 
    || (message?.sender?.id === loggedUser.id && message.message_delete_status === 1) || (message.message_delete_status === 2)
    return (
        <div
            style={{ display: messageNotToShow && "none" }}
            id={`message-${message.message_id}`} data-message-id={`${message.message_id}`}
            key={message.message_id}
            className={`chatBoxMsg ${message?.sender?.id === loggedUser.id
                ? "chatBoxMsgActiveUser"
                : "chatBoxMsgOtherUser"
                }`}
        >
            <div className="chatBoxMsgContainer"
            >
                {message?.sender?.id !== loggedUser.id && <div className="chatBoxMsgLeftImgContainer"
                >
                    <img
                        src={img}
                        className="leftImg "
                        alt="userImage"
                        onError={handleError}
                    />
                </div>}
                <div style={{ position: "relative" }}
                    className={`chatBoxMsgBody ${message?.sender?.id === loggedUser.id ? " chatBoxMsgActiveUserMsgBody" : "chatBoxMsgOtherUserMsgBody"
                        }`}
                >
                    {<div className='moreFunc'><MdDelete style={{ color: "red", position: "absolute", left: message?.sender?.id === loggedUser.id && "-20px", right: message?.sender?.id !== loggedUser.id && "-20px", top: "0", height: "100%" }} onClick={() => openModal(message)} />
                    </div>}
                    {message?.event_id?.length > 0 ? (
                        // If the message is an event, display the image and description
                        <Link to={`${process.env.REACT_APP_WYZER_EVENT_LINK}/${message?.event_id}`}  style={{textDecoration:"none"}}   target="_blank"
                        rel="noopener noreferrer">
                            <div className='event-message'>
                                <div style={{ maxWidth: "200px", margin: "auto" }}>
                                    <img style={{ width: "100%", borderRadius: "5px" }} src={event?.original_image} alt='' />
                                </div>
                                <div style={{ wordBreak: 'break-all' ,fontWeight:"600"}}><p>{event?.title}</p></div>
                            </div>
                            {message?.markedText ? messageWithMarks : messageWithBreaks}
                        </Link>
                    ) : (
                        // If the message is not an event, display text or images accordingly
                        <>
                            {messageWithImage}
                            {message?.markedText ? messageWithMarks : messageWithBreaks}
                        </>
                    )}
                    <div className="chatBoxMsgTimeAndSign">
                        <p>{displayTime}</p>
                        {message?.sender?.id === loggedUser.id && <p style={{ fontSize: "14px" }} className="">
                            {isRead ? (
                                <IoCheckmarkDoneSharp
                                    className={`${isRead && "read"}`}
                                />
                            ) : (
                                <IoCheckmarkOutline />
                            )}
                        </p>}

                    </div>

                    {chosenEmoji && <div className='showEmoji' >
                        <p style={{ cursor: "pointer" }}>{chosenEmoji}</p>
                    </div>}
                </div>

                {message?.sender?.id === loggedUser.id && <div className="chatBoxMsgRightImgContainer"
                >
                    <img
                        src={img}
                        className="rightImg"
                        alt="userImage"
                        onError={handleError}
                    />
                </div>}
            </div>

        </div>
    );
};

export default Message;