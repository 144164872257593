import React, { useContext } from 'react';
import backGroundImg from '../../../Assests/logo/supportBot.png'
import './home.css'
import { AuthContext } from '../../../SharedContext/SharedContext';
import logo from '../../../Assests/logo/wyzer-logo.jpg'
const Home = () => {
    const { loggedUser } = useContext(AuthContext);
    // console.log("loggedUser", loggedUser);
    return (
        <div className="" style={{overflow:"auto", height:"100vh"}}
        >
            <div style={{ backgroundColor: "#e2f3fb", height: "50px", width: "100%" ,display:"flex", alignItems:"center",gap:"10px",padding:"0 20px"}}>
                <img src={loggedUser?.image?loggedUser?.image:logo} style={{ width: "40px", height: "40px", borderRadius: "100%", objectFit: 'cover', objectPosition: "top", }} alt='bot' />
                <div style={{color:'gray', fontSize:"12px"}}>
                    <p className="" >{loggedUser?.name?loggedUser?.name:"wyzer"}</p>
                    <p className="" >{loggedUser?.email?loggedUser?.email:"wyzer@gmail.com"}</p>
                </div>
            </div>
            <div className='home'>
                <img src={backGroundImg} alt='bot' />
                <p className="homeText"
                >Chat Support For {process.env.REACT_APP_AppName}</p>
            </div>

        </div>
    );
};

export default Home;