import React, { createContext, useEffect, useRef, useState } from 'react';
import {
    getFirestore,
    collection,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    doc,
    getDoc,
    getDocs,
    getCollections,
    updateDoc,
    where,
    serverTimestamp, increment, startAt, startAfter, endAt, endBefore, limit, Timestamp,
    deleteDoc
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from '../Firebase/firebase';
import logo from '../Assests/logo/wyzer-logo.jpg'

export const AuthContext = createContext();
const database = getFirestore(app);
export const storage = getStorage(app);
const current_timestamp = Timestamp.fromDate(new Date());

const SharedContext = ({ children }) => {
    const [loadUser, setLoadUser] = useState(false)
    const [nextChannelLoad, setNextChannelLoad] = useState(false)
    let [loggedUser, setLoggedUser] = useState({
        id: 1,
        name: "wyzer",
        img: logo,
        email: "wyzer@gmail.com"
    }); 
    const [user, setUser] = useState({ email: "", isAuthenticated: false });
    const [channels, setChannels] = useState([]);
    const [channelsToShow, setChannelsToShow] = useState([]);
    const [oldestTimestamp, setOldestTimestamp] = useState(current_timestamp);
    const [loading, setLoading] = useState(true);
    const chatDocumentReference = collection(
        database,
        `${process.env.REACT_APP_CHATINDEX}`,
    );


    useEffect(() => {
        setLoading(true);
        const authStatus = localStorage.getItem('wyzer-auth');
        if (authStatus) {
            let auth = JSON.parse(authStatus)
            setUser(auth);
        }
        setLoading(false);
    }, []);
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BaseUrl}/chat/user-detail/1`)
            .then(res => res.json())
            .then(userInfo => {
                setLoggedUser(userInfo?.data?.user ? userInfo?.data?.user : loggedUser)
            });
    }, []);

    const login = async (email, password) => {
        let responsed = ""
        await fetch(`${process.env.REACT_APP_BaseUrl}/user/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email,
                password,
                "medium": "password"
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.token && data.message === "Login Successful") {
                    let response = { token: data.token, message: data.message, email, isAuthenticated: true }
                    setUser(response)
                    localStorage.setItem('wyzer-auth', JSON.stringify(response));
                    responsed = response
                    return response
                } else {
                    let response = { token: '', message: data.message, email: "", isAuthenticated: false }
                    localStorage.setItem('wyzer-auth', JSON.stringify(response));
                    setUser(response)
                    responsed = response
                    return response
                }
            });

        return responsed;
    }
    const logout = () => {
        return new Promise((resolve, reject) => {
            setUser({ email: "", isAuthenticated: false });
            localStorage.setItem('wyzer-auth', JSON.stringify({ email: "", isAuthenticated: false }));
            resolve("success")

        })
    };

    const fetchChatIndexes = async (oldestTimestamp = null, searchQuery = '') => {
        if (oldestTimestamp === null) {
            setLoadUser(true);
        }
        let queryRef = query(
            chatDocumentReference,
            where('thread_member_ids', 'array-contains', loggedUser.id),
            orderBy('message_time', 'desc'),
            limit(500)
        );

        if (oldestTimestamp) {
            setNextChannelLoad(true);
            queryRef = query(
                chatDocumentReference,
                where('thread_member_ids', 'array-contains', loggedUser.id),
                orderBy('message_time', 'desc'),
                startAfter(oldestTimestamp),
                limit(500)
            );
        }

        const unsubscribe = onSnapshot(queryRef, async (querySnapshot) => {
            const _channels = [];
            querySnapshot.forEach((snapshot) => {
                const d = snapshot?.data();
                _channels?.push({ ...d, index_id: snapshot.id });
            });

            let newOldestTimestamp = null;
            if (_channels?.length > 0) {
                newOldestTimestamp = querySnapshot?.docs[querySnapshot.docs.length - 1].data()?.message_time;
            };
            if (newOldestTimestamp !== null) {
                setOldestTimestamp(newOldestTimestamp);
            };
            _channels.forEach( (data) => {
                setChannels((prevList) => updateList(prevList, data));
                setChannelsToShow((prevList) => updateList(prevList, data));

            });
            setLoadUser(false);
            setNextChannelLoad(false);
        });

        // Remember to unsubscribe when the component unmounts or is no longer needed
        return () => unsubscribe();
    };
    const updateList = (prevList, data) => {
        const existingUserIndex = prevList.findIndex((user) => user.index_id == data.index_id);
        const loggedUserMember = data?.members?.find(member => member?.id == loggedUser.id);


        // Extract the counter value if the member is found
        const counter = loggedUserMember ? loggedUserMember.unread_count : 0;
        if (existingUserIndex !== -1) {
            // User already exists, update the counter value
            const updatedList = [...prevList];
            updatedList[existingUserIndex] = {
                ...updatedList[existingUserIndex],
                ...data, // Replace with the entire data object
                // counter: data.counter,
                // message_time: data.message_time,
                // message_id: data.message_id,
                // message: data.message,
                // message_delete_status: data.message_delete_status,
                members: data?.members?.map(member => {
                    if (member?.id === loggedUser?.id) {
                        return {
                            ...member,
                            unread_count: counter
                        };
                    }
                    return member;
                })

            };
            return updatedList.sort(customeSort)

        } else {
            // User doesn't exist, add to the list
            return [...prevList, data].sort(customeSort)
        }
    };

    function customeSort(a, b) {
        const timeA = a.message_time || 0;
        const timeB = b.message_time || 0;

        // Otherwise, sort based on times
        return timeB - timeA;
    }

    useEffect(() => {
        fetchChatIndexes();
    }, []);

    const nextChannels = () => {
        fetchChatIndexes(oldestTimestamp);
    };

    const searchChannels = async (searchQuery = '') => {
        setLoading(true);
        if (searchQuery) {
            const q = query(chatDocumentReference,
                where("thread_name", ">=", `${searchQuery}`),
                where("thread_name", "<=", `${searchQuery}\uf8ff`),
                // where('members', 'array-contains', { name: `${searchQuery}` })
            )
            const doc_refs = await getDocs(q);

            const res = [];

            doc_refs.forEach(country => {
                res.push({
                    // id: country.id,
                    ...country.data()
                });
            });

            if (res.length === 0) {
                const q = query(chatDocumentReference,
                    where('members', 'array-contains', { name: searchQuery })
                )
                const doc_refs = await getDocs(q);
                doc_refs.forEach(doc => {
                    res.push({
                        // id: doc.id,
                        ...doc.data()
                    });
                });
            }

            setChannels(res);
            setChannelsToShow(res);
            setLoading(false);
        }
        else {
            fetchChatIndexes();
        }

    };

    async function deleteCollection( collectionPath ) {
        const collectionRef = collection(database, collectionPath);

        return new Promise((resolve, reject) => {
            deleteQueryBatch(collectionRef, resolve).catch(reject);
        });
    };

    async function deleteQueryBatch(collectionRef, resolve) {
        const snapshot = await getDocs(collectionRef);
    
        const promises = [];
        snapshot.forEach(doc => {
            promises.push(deleteDoc(doc.ref));
        });
    
        await Promise.all(promises);
    
        // After deleting all documents in the collection, resolve the promise
        resolve();
    };

    async function deleteDocument(documentPath,index) {
        const documentRef = doc(database, documentPath);
        await deleteDoc(documentRef);
        let updatedChannels = channels.filter(channel=>channel.doc_id!==index)
        setChannels(updatedChannels);
        setChannelsToShow(updatedChannels);

    };

    async function deleteCollectionAndDocument(collectionPath,documentPath,index) {
        // First, delete the collection
        // let collectionPath = `${process.env.REACT_APP_CHATINDEX}/${index}/messages`;
        // await deleteCollection(collectionPath);
        
        // Then, delete the specific document
        // let documentPath = `${process.env.REACT_APP_CHATINDEX}/${index}`;
        await deleteDocument(documentPath,index);
    };

    const allInfo = {
        loggedUser,
        loading,
        channels,
        setChannels,
        channelsToShow,
        login,
        logout,
        user,
        nextChannels,
        searchChannels,
        loadUser,
        nextChannelLoad,
        deleteCollectionAndDocument
    };
    return (

        <div>
            <AuthContext.Provider value={allInfo}>{children}</AuthContext.Provider>
        </div>
    );
};

export default SharedContext;
