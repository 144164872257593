import React from 'react';

const FullPageLoader = () => {
    return (
        <div className='fullPageLoaderBody'>
            <div className="fullPageLoader"></div>
        </div>
    );
};

export default FullPageLoader;