import React from 'react';
import './deleteChannelModal.css'

const DeleteChannelModal = ({deleteChannelAndMessages, setShowDeleteChannelModal} ) => {
    return (
        <div className='deleteChannelBtnContainer'>
            <div className='deleteChannelBtns'>
                <h3>Do you really want to delete this channel</h3>
                <div className='deleteChannelsBtns' >
                    <button onClick={() => deleteChannelAndMessages()} className='yesDelete'>Yes</button>
                    <button className='closeDeleteChannelBtn' onClick={()=>setShowDeleteChannelModal(false)}>No</button>
                </div>
            </div>
        </div>
    );
};

export default DeleteChannelModal;