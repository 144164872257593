import React, { useContext, useEffect, useState } from 'react';
import './OverView.css'
import logo from '../../../Assests/logo/wyzer-logo.jpg'
import { Link } from 'react-router-dom';
import { FaArrowCircleRight } from "react-icons/fa";

const OverView = ({ image, name, created_at, moreDetailsToShow, otherParticipant }) => {

    const handleError = (event) => {
        event.target.src = logo;
    };

    return (
        <div>
            <div className="viewDetailsContainerImg">
                <img onError={handleError} src={image ? image : logo} alt='userImage' />
            </div>
            <p className="viewDetailsContainerName">{name}</p>
            <div style={{ marginBottom: "20px" }}>
                <p>Created</p>
                <p style={{ color: "gray" }}>{created_at}</p>
            </div>
            <Link style={{ textDecoration: "none", }} target="_blank" rel="noopener noreferrer" to={`https://admin.wyzrfriends.com/admin/user/details/${otherParticipant?.id}`}>
                <button className='overviewChannelDetailsBtn'>See Details<FaArrowCircleRight /> </button>
            </Link>
        </div>
    );
};

export default OverView;