import React, { useEffect, useState } from 'react';
import { RiMenu5Fill } from "react-icons/ri";
import './Filter.css'
const Filter = ({ filterMenu, title,setFilterText,handleFiltersChannels,filterText}) => {
    const [showFilterOptions, setShowFilterOptions] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isClickInsideFilterBox = event.target.closest(".filterListContainer");
            if (!isClickInsideFilterBox) {
                setFilterText('');
                setShowFilterOptions(false);
            };
        };

        document.body.addEventListener("click", handleClickOutside);

        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, []);
    return (
        <div className='filterListContainer'>
            <RiMenu5Fill className='filterIcon' onClick={() => {setShowFilterOptions(!showFilterOptions)
            handleFiltersChannels('')}} />
            <div className={`filterBody ${showFilterOptions ? "top-5 duration-500 ease-in-out" : "-top-96"}`}  style={{top:showFilterOptions?"1.25rem":"-24rem"}}>
                <p className='filterTitle'>{title}</p>
                <div className='filterLists'>
                    {
                        filterMenu.map((item,i) => <div onClick={()=>handleFiltersChannels(item.value)} key={item.id} className={`${filterText==item.value?"filterActive":"notActive"} filterList flex items-center gap-2 text-sm font-medium text-gray cursor-pointer`}>
                            <item.icon />
                            <p>{item.name}</p>
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
};

export default Filter;